.media-fit{
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}
.media-fit__inner{
  position: absolute;
  width:100%;
  height:100%;
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}    

.home-walk{
    width: 100%;
    transition: all 600ms cubic-bezier(0.22, 1, 0.36, 1);
    &, *{
        box-sizing: border-box;
    }
    a.home-walk__btn{
        display: inline-block;
        padding: 15px 50px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        color: #fff;
        background-color: #00AEEF;
        letter-spacing: 0.05em;
    }
}

.home-walk__full-vid{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity:0;
    transition: opacity 400ms ease-in;
    &.js-loaded {
        opacity:1;
    }
}



.home-walk__triangles-top, .home-walk__triangles-bottom{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index:1;
    pointer-events: none;
    transition: all 400ms cubic-bezier(0.22, 1, 0.36, 1); 
    visibility: hidden;
    div{
        position: absolute;
        top: 0;
        left: -50vw;
        width: 200vw;
        height:50vh;
        transform-origin: center;
        opacity:0;
        transition: all 1500ms cubic-bezier(0.22, 1, 0.36, 1); 
    }
}
.home-walk__triangles-top{
    div:nth-child(1){
        background-color: #002B54;
        transform: translate3d(0,-65vh, 0) rotateZ(-15deg);
    }
    div:nth-child(2){
        background-color: #00AEEF;
        transform: translate3d(0,-85vh, 0) rotateZ(15deg);
    }
}
.home-walk__triangles-bottom{
    div:nth-child(1){
        background-color: #002B54;
        transform: translate3d(0, 100vh, 0) rotateZ(15deg);
    }
    div:nth-child(2){
        background-color: #00AEEF;
        transform: translate3d(0, 120vh, 0) rotateZ(-15deg);
    }
}
.home-walk:not([data-current-section="0"]){
    .home-walk__intro{
        opacity:0;
    }
}


@mixin section-definition($bgColor, $headerColor, $textColor, $arrowColor, $triCol1, $triCol2, $topTri, $showDots){
    background-color: $bgColor; 
            @if($topTri){
                .home-walk__triangles-top{
                    div:nth-child(1){
                        opacity:65%;
                        background-color: $triCol1;
                            transform: translate3d(0,-45vh, 0) rotateZ(-15deg);
                    }
                    div:nth-child(2){
                        opacity:65%;
                        background-color: $triCol2;
                            transform: translate3d(0,-45vh, 0) rotateZ(15deg);
                    }
                }
            } @else {
                .home-walk__triangles-bottom{
                    div:nth-child(1){
                        opacity:65%;
                        background-color: $triCol1;
                            transform: translate3d(0,80vh, 0) rotateZ(15deg);
                    }
                    div:nth-child(2){
                        opacity:65%;
                        background-color: $triCol2;
                            transform: translate3d(0,100vh, 0) rotateZ(-15deg);
                    }
                }
            }

    @if($showDots){
        .home-walk__scroll-points-tracker{
            opacity:1;
            transform: translate3d(0,0,0);
        }
    }
}

.home-walk[data-current-section="0"]{
    @include section-definition(#002B54, #fff, #fff, #fff, #002B54, #00AEEF, false, false);
    .home-walk__triangles-bottom{
        visibility: visible;
    }
}
.home-walk[data-current-section="1"]{
    @include section-definition(#fff, #002B54, #002B54, #002B54, #59CAF5, #00AEEF, true, true);
}
.home-walk[data-current-section="2"]{
    @include section-definition(#002B54, #59CAF5, #fff, #59CAF5, #59CAF5, #00AEEF, false, true);
}
.home-walk[data-current-section="3"]{
    @include section-definition(#fff, #002B54, #002B54, #002B54, #59CAF5, #00AEEF, true, true);
}
.home-walk[data-current-section="4"]{
    @include section-definition(#4BABD3, #002B54, #fff, #002B54, #002B54, #00AEEF, false, true);
}
.home-walk[data-current-section="5"]{
    @include section-definition(#fff, #002B54, #002B54, #002B54, #59CAF5, #00AEEF, true, false);
}

.home-walk__intro{
    width: 100%;
    height: calc(100vh - 118px);
    position: relative;
    background-color: lighten(#002B54, 5%);
    transition: all 600ms cubic-bezier(0.23, 1, 0.320, 1);
    overflow: hidden;
    background-size: cover;
    background-position: center;
    &:after{
        content: "";
        display: block;
        position: absolute;
        bottom:0;
        left:0;
        z-index:1;
        width: 100%;
        height: 200px;
        background: linear-gradient(to top, #002B54 0%, transparentize(#002B54, 1) 100%);
    }
}

@keyframes in-top{
    0%{ opacity: 0; transform: translate3d(0,-100px, 0)}
    100%{ opacity: 1; transform: translate3d(0,0,0)}
}
@keyframes in-bottom{
    0%{ opacity: 0; transform: translate3d(0,100px, 0)}
    100%{ opacity: 1; transform: translate3d(0,0,0)}
}
@keyframes in-left{
    0%{ opacity: 0; transform: translate3d(-60px,0, 0)}
    100%{ opacity: 1; transform: translate3d(0,0,0)}
}
@keyframes in-zoom{
    0%{ opacity: 0; transform: translate3d(-60px,0, 0) scale3d(1.5, 1.5, 1); filter: blur(20px)}
    10%{ opacity: 1; transform: translate3d(0,0, 0) scale3d(1, 1, 1); filter: blur(0px)}
    100%{ opacity: 1; transform: translate3d(0,0, 0) scale3d(1.3, 1.3, 1); filter: blur(0px)}
}
@keyframes in-fade{
    0%{ opacity: 0; transform: translate3d(-60px,0, 0) scale3d(1.5, 1.5, 1); filter: blur(20px)}
    100%{ opacity: 1; transform: translate3d(0,0, 0) scale3d(1, 1, 1); filter: blur(0px)}
}
@keyframes home-walk__intro-init{
    0%{
        left: 49%;
        font-size: 28px;
        font-weight: 400;
        text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    }
    100%{
        bottom: 3%;
        left: 3%;
        font-size: 18px;
        padding: 0;
        width: 320px;
    }
}
@keyframes home-walk__intro-init-mobile{
    0%{
        top: 1%;
    }
    100%{
        top: 2%;
    }
}
.home-walk__intro-init{
    position: absolute;
    bottom: 15%;
    left: 49%;
    font-size: 28px;
    z-index: 2;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    width:50%;
    padding: 0 10%;
    text-align: center;
    animation: home-walk__intro-init 2000ms 4150ms cubic-bezier(0.22, 1, 0.36, 1) both;
    @media (min-width: 1400px){
        padding: 0 15%;
        bottom: 20%;
    }
    @media (max-width: 1080px){
        right: calc(50% - 160px);
        top: 2%;
        bottom: auto;
        font-size: 14px;
        margin-top: 0;
        left: auto;
        width: 320px;
        animation: home-walk__intro-init-mobile 600ms  cubic-bezier(0.22, 1, 0.36, 1) both;
    }
}
.home-walk__intro-content{
    position: absolute;
    z-index:2;
    width:50%;
    right: 0;
    top: 0;
    height:100%;
    color: #fff;
    > div:nth-child(1){
        position: relative;
        top:20%;
        width: 341px;
        left:calc(50% - 170px);
        @media(max-height:720px){
            width:300px;
            left: calc(50% - 125px);
        }
        h1{
            color: #59CAF5;
            font-size: 40px;
            font-weight: 400;
            position: absolute;
            top:-90px;
            left: 84px;
            animation: in-top 1200ms 200ms cubic-bezier(0.22, 1, 0.36, 1) both;
        }
        img{
            max-width: 100%;
            position: absolute;
            z-index:1;
            &:nth-child(2){
                animation: in-bottom 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
            }
            &:nth-child(3){
                top: 66px;
                left:-60px;
                z-index:2;
                animation: in-left 600ms 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
            }
            &:nth-child(4){
                top:137px;
                animation: in-top 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
            }
        }
    }
    p{
        width: 300px;
        text-align: center;
        position: absolute;
        top: 60%;
        left: calc(50% - 150px);
        font-weight: 300;
        font-size: 22px;
        line-height: 29px;
        @media(min-width:600px){
            animation: in-zoom 12s linear both;
        }
        @media(max-width:600px){
            animation: in-fade 800ms linear both;
        }
    }
    div:last-child{
        position: absolute;
        width: 65px;
        left: calc(50% - 33px);
        bottom: 15%;
        text-align: center;
        z-index:5;
        animation: in-top 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
        svg{
            display: inline;
            transform: rotateZ(90deg);
            fill: #fff;
            margin-top: 30px;
        }
        @media(max-height:960px){
            bottom: 10%;
        }
        @media(max-height:720px){
            bottom: 5%;
        }
    }
    @media(max-width: 1080px){
        width: 100%;
    }
    @media (max-height: 1080px) and (min-height: 840px) and (min-width: 800px) {
        > div:first-child{
            top: 12%;
        }
        p{
           top: 50%; 
        }
        > div:last-child{
            bottom: 17%;
        }
    }
    @media (max-height: 840px) and (min-width: 800px) {
        p{
           top: 65%; 
        }
        > div:last-child{
            bottom: 17%;
            left: calc(10% - 33px);
            width: 200px;
            left: 200px;
            bottom:8%;
            svg{
                margin-top: 0;
                margin-left: 15px;
                position: relative;
                top: 10px;
            }
        }
    }

    @media(max-width: 800px) {
        > div:nth-child(1){
            transform:scale3d(0.65,0.65,1);
            top:4%;
        }
        p{
            top:50%;
            font-size: 16px;
        }
    }
    @media(max-width: 720px){
        > div:nth-child(1){
            transform:scale3d(0.65,0.65,1);
            top:21%;
        }
    }
    @media(max-width: 340px) {
        > div:nth-child(1){
            transform:scale3d(0.5,0.5,1);
            top:22%;
            left: calc(50% - 150px);
        }
        p{
            top:50%;
            font-size: 18px;
        }
    }
}

@media (max-width: 720px) {
    .home-walk .home-walk__intro-content{
        display: block;
        > div:nth-child(1){
            h1{
                display: block;
            }
            img{
                &:nth-child(2){
                    display: block;
                }
                &:nth-child(3){
                    display: block;
                }
                &:nth-child(4){
                    display: block;
                }
            }
        }
        p{
            display: block;
        }
        div:last-child{
            display: block;
        }
    }
}

@media (min-width: 720px){
    .home-walk[data-intro-stage="0"] {

       .home-walk__intro-content{
            display: none;
        }
        .home-walk__intro-init{
            display: none;
        }
    }
    .home-walk[data-intro-stage="1"]{
        .home-walk__intro-init{
            display: none;
        }
    }
    .home-walk[data-intro-stage="2"] {

    .home-walk__intro-content{
        display: none;
        > div:nth-child(1){
            h1{
                display: none;
            }
            img{
                &:nth-child(2){
                    display: none;
                }
                &:nth-child(3){
                    display: none;
                }
                &:nth-child(4){
                    display: none;
                }
            }
        }
        p{
            display: none;
        }
        div:last-child{
            display: none;
        }
    }}

    .home-walk[data-intro-stage="3"] .home-walk__intro-content{
        display: flex;
        > div:nth-child(1){
            h1{
                display: block;
            }
            img{
                &:nth-child(2){
                    display: block;
                }
                &:nth-child(3){
                    display: block;
                }
                &:nth-child(4){
                    display: block;
                }
            }
        }
        p{
            display: none;
        }
        div:last-child{
            display: none;
        }
    }
    .home-walk[data-intro-stage="4"] .home-walk__intro-content{
        display: flex;
        > div:nth-child(1){
            h1{
                display: block;
            }
            img{
                &:nth-child(2){
                    display: block;
                }
                &:nth-child(3){
                    display: block;
                }
                &:nth-child(4){
                    display: block;
                }
            }
        }
        p{
            display: block;
        }
        div:last-child{
            display: none;
        }
    }
    .home-walk[data-intro-stage="5"] .home-walk__intro-content{
        display: flex;
        > div:nth-child(1){
            h1{
                display: block;
            }
            img{
                &:nth-child(2){
                    display: block;
                }
                &:nth-child(3){
                    display: block;
                }
                &:nth-child(4){
                    display: block;
                }
            }
        }
        p{
            display: block;
        }
        div:last-child{
            display: block;
        }
    }
}


@media (max-width: 420px) and (min-height: 600px) {
    .home-walk__intro-content{
        p{
            top:53% !important;
        }
        > div:last-child{
            bottom: 10% !important;
        }
    }
}



$home-walk__section-height: 100vh;
.home-walk__scroll-points{
    width: 100%;
    height: calc($home-walk__section-height * 4);
}

.home-walk__scroll-points-tracker{
    position: sticky;
    z-index:4;
    top: 50%;
    height: 0;
    width:100vw;
    list-style-type: none;
    margin: 0;
    padding-left: calc(100vw - 80px);
    @media(max-width: 960px){
        padding-left: calc(100vw - 45px);
    }
    @media(max-width: 920px){
        display: none;
    }
    opacity:0;
    transform: translate3d(200px,0,0);
    transition: all 1200ms cubic-bezier(0.22, 1, 0.36, 1); 

    li{
        width: 20px;
        height: 20px;
        background-color: #c4c4c4;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        border-radius: 100%;
        cursor: pointer;
    }

    .home-walk[data-current-section="1"] & li:nth-child(1),
    .home-walk[data-current-section="2"] & li:nth-child(2),
    .home-walk[data-current-section="3"] & li:nth-child(3),
    .home-walk[data-current-section="4"] & li:nth-child(4),
    .home-walk[data-current-section="5"] & li:nth-child(5){ background-color: #59CAF5; }
}

@mixin enable-point-visibility($sectionnum){
    .home-walk[data-current-section="#{$sectionnum}"] .home-walk__scroll-point{
        &:nth-child(#{$sectionnum + 1}){
        .home-walk__triangles-bottom, .home-walk__triangles-top{
            visibility: visible;
        }
            h2{transform: translate3d(0,0,0); opacity:1;}
            p{transform: translate3d(0,0,0); opacity:1;}
            a{transform: translate3d(0,0,0); opacity:1;}
            .home-walk__scroll-point-img{transform: translate3d(0,0,0); opacity:1;}
        }
    }
}
@include enable-point-visibility(1);
@include enable-point-visibility(2);
@include enable-point-visibility(3);
@include enable-point-visibility(4);
@include enable-point-visibility(5);

.home-walk__scroll-point{
    top:0;
    left: 0;
    width: 100%;
    height: $home-walk__section-height; 
    z-index:3;
    position: relative;
    overflow: hidden;
    @media (min-width: 660px){
        h2{
            transform: translate3d(0,25px,0); opacity:0;
            transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
        }
        p{transform: translate3d(0,50px,0); opacity:0;
            transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
        }
        a{transform: translate3d(0,70px,0); opacity:0;
            transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
        }
    }
    h2{
            transform: translate3d(0,0,0); 
    }
    p{
            transform: translate3d(0,0,0); 
    }
    a{
            transform: translate3d(0,0,0); 
    }
    &:nth-child(2){background-color: #fff; 
        a svg{ fill: #002B54}
        h2{color: #002B54}
        p{color: #002B54}
    }
    &:nth-child(3){background-color: #002B54;
        a svg{ fill: #59CAF5}
        h2{color: #59CAF5}
        p{color: #fff}
    }
    &:nth-child(4){background-color: #fff;
        a svg{ fill: #002B54}
        h2{color: #002B54}
        p{color: #002B54}
    }
    &:nth-child(5){background-color: #4BABD3;
        a svg{ fill: #002B54}
        h2{color: #002B54}
        p{color: #fff}
    }
    &:nth-child(6){background-color: #fff;
        a svg{ fill: #002B54}
        h2{color: #002B54}
        p{color: #002B54}
    }
}

.home-walk__scroll-point-wrap{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    clip: rect(0,auto,auto,0);
    overflow: hidden;
    z-index:2;
}
.home-walk__scroll-point-fixed{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 80px;
}
.home-walk__inner-scroll-point{
    max-width: 1200px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    @media(max-width: 960px){
        flex-wrap: wrap;
        height: 80vh;
        padding-top: 10vh;
        > *{
            width: 100% !important;
            padding: 0 !important;
        }
        .home-walk__scroll-point-content{
            order:2;
        }
        .home-walk__scroll-point-img{
            margin: 0 auto;
            order:1;
            margin-bottom: 0px;
            width: 50% !important;
            padding-bottom: 50% !important;
        }
    }
    @media(max-width: 720px){
        .home-walk__scroll-point-img{
            margin: 0 auto;
            order:1;
            margin-bottom: 0px;
            width: 100% !important;
            padding-bottom: calc(100% - 4px) !important;
            border: 2px solid #fff;
        }
        .home-walk__scroll-point-content{
            p{font-size: 22px;}
        }
    }

}
.home-walk__scroll-point-follow{
    text-align: center;
    width: 100%;
    padding-top: 20vh;
    h2{
        font-size: 60px;
        color: #00AEEF !important;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 72px;
    }
    h2 + p{
        font-size: 24px;
        padding: 0;
        margin: 0;
        color: #002B54;
        font-weight: 400;
    }
    ul{
        list-style-type: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        li{
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 300;
            color: #002B54;
            cursor: pointer;
            &:after{
                content:"|";
                display: inline-block;
                margin: 0 20px;
            }
            &:last-child:after{
                display: none;
            }
        }
        @media(max-width: 960px){
            display: block;
            margin-top: 30px;
            li{
                display: block;
                margin-bottom: 20px;
                &:after{
                    display: none;
                }
            }
        }
    }
    svg{
        margin-top: 40px;
        fill: #002B54;
        transform: rotateZ(90deg);
    }
    @media(max-width: 720px){
        h2{
            font-size: 26px;
            line-height: 32px;
        }
    }
}
.home-walk__scroll-point-content{
    width: 50%; 
    padding-right: 60px;
    h2{
        text-transform: uppercase;
        font-size: 25px;
        font-weight: normal;
        margin-bottom: 10px;
        letter-spacing: 0.05em;
    }
    p{
        margin: 0;
        font-size: 40px;
        font-weight: 300;
        padding: 0;
    }
    a{
        display: block;
        margin-top: 20px;
    }

    @media(max-width: 720px){
        p{
            font-size: 28px;
        }
    }
}
.home-walk__scroll-point-img{
    width: 50%;
    padding-bottom: 50%;
    background-color: #002B54;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    border: 6px solid #fff;
    position: relative;
    overflow: hidden;
    transform: translate3d(0,0,0);
}
.home-walk__point-vid{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate3d(0,0,0);
}

.home-walk__zoom-points-container{
    position: relative;
    width:100%;
    background-color: #002B54;
}
.home-walk__zoom-points{
    display: block;
    position: sticky;
    top:0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.home-walk__zoom-point{
   position: absolute;
   max-width: 960px;
   width: 100vw;
   transform: translateZ(-2000px);
   opacity: 0;
}
.home-walk__zoom-point-inner{
    display: block;
    background-color: transparentize(#fff, 0.2);
    backdrop-filter: blur(15px);
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    @media(max-width: 720px){
        width: 130%;
        padding-bottom: 130%;
        margin-left: -15%;
    }
}
.home-walk__zoom-point-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    color: #002B54;
    > div{
        position: absolute;
        width: 100%;
        left: 0;
        height: 100%;
        top:0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        padding: 100px;
        @media(max-width: 720px){
            padding: 20%;
            a{
                font-size: 12px !important;
                padding: 10px 30px !important;
            }
        }
        h2,h3,p{
            display: block;
            width: 100%;
        }
        a{
            display: inline-block;
        }
        h2{
            color: #002B54;
            font-size: 126px;
            font-weight: 100;
            margin-bottom: 10px;
            margin-top: 0;
        }
        h3{
            color: #002B54;
            font-weight: 400;
        }
        img{
            display: block;
            width: 100%;
            padding: 0 25%;
            height: auto;
            margin: 30px 0;
        }

        p{
            color: #000;
        }
    }
}
.home-walk__zoom-point-a{
    opacity:1;
    h3{
        font-size: 46px;
    }

    @media(max-width: 720px){
        h2{
            font-size: 58px !important;
        }
        h3{
            font-size: 28px;
        }
    }
}
.home-walk__zoom-point-b{
    opacity:0;
    transform: translateY(100px);
    h3{
        font-size: 40px;
        margin-bottom: 20px;
    }
    p{
        display: block;
        font-size: 16px;
        margin: 0;
        padding: 0 3.5vw;
        + p {
            margin-top: 20px;
        }
    }
    a{
        margin-top: 10px;
    }
    @media(max-width: 720px){
        h3{
            font-size: 26px;
        }
        p{
            padding: 0;
            font-size: 14px;
            & + p {
                margin-top: 10px;
            }
        }
        a{
            margin-top: 20px;
        }
        img{display: none !important;}
    }
}
.home-walk__conclude{
    min-height: 35vh;
    padding: 100px 0;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    background-color: #E7EEF2;
    overflow: hidden;
    svg{
        position: absolute;
        top: 0;left: 0;
        z-index:1;
    }
    div{
        position: relative;
        z-index:2;
        width: 100%;
    }
    h3{
        width: 100%;
        font-size: 40px;
        font-weight: 100;
        color: #0980B2 ;
        margin-bottom: 10px;
    }
    p{
        font-size: 16px;
    }
    a{
        margin-top: 40px;
    }

    height: calc(100vh - 131px) !important;
}

.home-walk__next-action{
    cursor: pointer;
}


header{
    z-index:10;
    position: relative;
    background-color: #01386A;
    > div{
        height:112px !important;
    }
    .menu-item a{
        color: #fff !important;
    }
}
footer{
    margin-top: 0 !important;
}