.media-fit {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}

.media-fit__inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.media-fit__inner > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-walk {
  width: 100%;
  transition: all 600ms cubic-bezier(0.22, 1, 0.36, 1);
}
.home-walk, .home-walk * {
  box-sizing: border-box;
}
.home-walk a.home-walk__btn {
  display: inline-block;
  padding: 15px 50px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  color: #fff;
  background-color: #00AEEF;
  letter-spacing: 0.05em;
}

.home-walk__full-vid {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 400ms ease-in;
}
.home-walk__full-vid.js-loaded {
  opacity: 1;
}

.home-walk__triangles-top, .home-walk__triangles-bottom {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  transition: all 400ms cubic-bezier(0.22, 1, 0.36, 1);
  visibility: hidden;
}
.home-walk__triangles-top div, .home-walk__triangles-bottom div {
  position: absolute;
  top: 0;
  left: -50vw;
  width: 200vw;
  height: 50vh;
  transform-origin: center;
  opacity: 0;
  transition: all 1500ms cubic-bezier(0.22, 1, 0.36, 1);
}

.home-walk__triangles-top div:nth-child(1) {
  background-color: #002B54;
  transform: translate3d(0, -65vh, 0) rotateZ(-15deg);
}
.home-walk__triangles-top div:nth-child(2) {
  background-color: #00AEEF;
  transform: translate3d(0, -85vh, 0) rotateZ(15deg);
}

.home-walk__triangles-bottom div:nth-child(1) {
  background-color: #002B54;
  transform: translate3d(0, 100vh, 0) rotateZ(15deg);
}
.home-walk__triangles-bottom div:nth-child(2) {
  background-color: #00AEEF;
  transform: translate3d(0, 120vh, 0) rotateZ(-15deg);
}

.home-walk:not([data-current-section="0"]) .home-walk__intro {
  opacity: 0;
}

.home-walk[data-current-section="0"] {
  background-color: #002B54;
}
.home-walk[data-current-section="0"] .home-walk__triangles-bottom div:nth-child(1) {
  opacity: 65%;
  background-color: #002B54;
  transform: translate3d(0, 80vh, 0) rotateZ(15deg);
}
.home-walk[data-current-section="0"] .home-walk__triangles-bottom div:nth-child(2) {
  opacity: 65%;
  background-color: #00AEEF;
  transform: translate3d(0, 100vh, 0) rotateZ(-15deg);
}
.home-walk[data-current-section="0"] .home-walk__triangles-bottom {
  visibility: visible;
}

.home-walk[data-current-section="1"] {
  background-color: #fff;
}
.home-walk[data-current-section="1"] .home-walk__triangles-top div:nth-child(1) {
  opacity: 65%;
  background-color: #59CAF5;
  transform: translate3d(0, -45vh, 0) rotateZ(-15deg);
}
.home-walk[data-current-section="1"] .home-walk__triangles-top div:nth-child(2) {
  opacity: 65%;
  background-color: #00AEEF;
  transform: translate3d(0, -45vh, 0) rotateZ(15deg);
}
.home-walk[data-current-section="1"] .home-walk__scroll-points-tracker {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.home-walk[data-current-section="2"] {
  background-color: #002B54;
}
.home-walk[data-current-section="2"] .home-walk__triangles-bottom div:nth-child(1) {
  opacity: 65%;
  background-color: #59CAF5;
  transform: translate3d(0, 80vh, 0) rotateZ(15deg);
}
.home-walk[data-current-section="2"] .home-walk__triangles-bottom div:nth-child(2) {
  opacity: 65%;
  background-color: #00AEEF;
  transform: translate3d(0, 100vh, 0) rotateZ(-15deg);
}
.home-walk[data-current-section="2"] .home-walk__scroll-points-tracker {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.home-walk[data-current-section="3"] {
  background-color: #fff;
}
.home-walk[data-current-section="3"] .home-walk__triangles-top div:nth-child(1) {
  opacity: 65%;
  background-color: #59CAF5;
  transform: translate3d(0, -45vh, 0) rotateZ(-15deg);
}
.home-walk[data-current-section="3"] .home-walk__triangles-top div:nth-child(2) {
  opacity: 65%;
  background-color: #00AEEF;
  transform: translate3d(0, -45vh, 0) rotateZ(15deg);
}
.home-walk[data-current-section="3"] .home-walk__scroll-points-tracker {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.home-walk[data-current-section="4"] {
  background-color: #4BABD3;
}
.home-walk[data-current-section="4"] .home-walk__triangles-bottom div:nth-child(1) {
  opacity: 65%;
  background-color: #002B54;
  transform: translate3d(0, 80vh, 0) rotateZ(15deg);
}
.home-walk[data-current-section="4"] .home-walk__triangles-bottom div:nth-child(2) {
  opacity: 65%;
  background-color: #00AEEF;
  transform: translate3d(0, 100vh, 0) rotateZ(-15deg);
}
.home-walk[data-current-section="4"] .home-walk__scroll-points-tracker {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.home-walk[data-current-section="5"] {
  background-color: #fff;
}
.home-walk[data-current-section="5"] .home-walk__triangles-top div:nth-child(1) {
  opacity: 65%;
  background-color: #59CAF5;
  transform: translate3d(0, -45vh, 0) rotateZ(-15deg);
}
.home-walk[data-current-section="5"] .home-walk__triangles-top div:nth-child(2) {
  opacity: 65%;
  background-color: #00AEEF;
  transform: translate3d(0, -45vh, 0) rotateZ(15deg);
}

.home-walk__intro {
  width: 100%;
  height: calc(100vh - 118px);
  position: relative;
  background-color: #00386e;
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.home-walk__intro:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200px;
  background: linear-gradient(to top, #002B54 0%, rgba(0, 43, 84, 0) 100%);
}

@keyframes in-top {
  0% {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes in-bottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes in-left {
  0% {
    opacity: 0;
    transform: translate3d(-60px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes in-zoom {
  0% {
    opacity: 0;
    transform: translate3d(-60px, 0, 0) scale3d(1.5, 1.5, 1);
    filter: blur(20px);
  }
  10% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1.3, 1.3, 1);
    filter: blur(0px);
  }
}
@keyframes in-fade {
  0% {
    opacity: 0;
    transform: translate3d(-60px, 0, 0) scale3d(1.5, 1.5, 1);
    filter: blur(20px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    filter: blur(0px);
  }
}
@keyframes home-walk__intro-init {
  0% {
    left: 49%;
    font-size: 28px;
    font-weight: 400;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  100% {
    bottom: 3%;
    left: 3%;
    font-size: 18px;
    padding: 0;
    width: 320px;
  }
}
@keyframes home-walk__intro-init-mobile {
  0% {
    top: 1%;
  }
  100% {
    top: 2%;
  }
}
.home-walk__intro-init {
  position: absolute;
  bottom: 15%;
  left: 49%;
  font-size: 28px;
  z-index: 2;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  width: 50%;
  padding: 0 10%;
  text-align: center;
  animation: home-walk__intro-init 2000ms 4150ms cubic-bezier(0.22, 1, 0.36, 1) both;
}
@media (min-width: 1400px) {
  .home-walk__intro-init {
    padding: 0 15%;
    bottom: 20%;
  }
}
@media (max-width: 1080px) {
  .home-walk__intro-init {
    right: calc(50% - 160px);
    top: 2%;
    bottom: auto;
    font-size: 14px;
    margin-top: 0;
    left: auto;
    width: 320px;
    animation: home-walk__intro-init-mobile 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
  }
}

.home-walk__intro-content {
  position: absolute;
  z-index: 2;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  color: #fff;
}
.home-walk__intro-content > div:nth-child(1) {
  position: relative;
  top: 20%;
  width: 341px;
  left: calc(50% - 170px);
}
@media (max-height: 720px) {
  .home-walk__intro-content > div:nth-child(1) {
    width: 300px;
    left: calc(50% - 125px);
  }
}
.home-walk__intro-content > div:nth-child(1) h1 {
  color: #59CAF5;
  font-size: 40px;
  font-weight: 400;
  position: absolute;
  top: -90px;
  left: 84px;
  animation: in-top 1200ms 200ms cubic-bezier(0.22, 1, 0.36, 1) both;
}
.home-walk__intro-content > div:nth-child(1) img {
  max-width: 100%;
  position: absolute;
  z-index: 1;
}
.home-walk__intro-content > div:nth-child(1) img:nth-child(2) {
  animation: in-bottom 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
}
.home-walk__intro-content > div:nth-child(1) img:nth-child(3) {
  top: 66px;
  left: -60px;
  z-index: 2;
  animation: in-left 600ms 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
}
.home-walk__intro-content > div:nth-child(1) img:nth-child(4) {
  top: 137px;
  animation: in-top 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
}
.home-walk__intro-content p {
  width: 300px;
  text-align: center;
  position: absolute;
  top: 60%;
  left: calc(50% - 150px);
  font-weight: 300;
  font-size: 22px;
  line-height: 29px;
}
@media (min-width: 600px) {
  .home-walk__intro-content p {
    animation: in-zoom 12s linear both;
  }
}
@media (max-width: 600px) {
  .home-walk__intro-content p {
    animation: in-fade 800ms linear both;
  }
}
.home-walk__intro-content div:last-child {
  position: absolute;
  width: 65px;
  left: calc(50% - 33px);
  bottom: 15%;
  text-align: center;
  z-index: 5;
  animation: in-top 600ms cubic-bezier(0.22, 1, 0.36, 1) both;
}
.home-walk__intro-content div:last-child svg {
  display: inline;
  transform: rotateZ(90deg);
  fill: #fff;
  margin-top: 30px;
}
@media (max-height: 960px) {
  .home-walk__intro-content div:last-child {
    bottom: 10%;
  }
}
@media (max-height: 720px) {
  .home-walk__intro-content div:last-child {
    bottom: 5%;
  }
}
@media (max-width: 1080px) {
  .home-walk__intro-content {
    width: 100%;
  }
}
@media (max-height: 1080px) and (min-height: 840px) and (min-width: 800px) {
  .home-walk__intro-content > div:first-child {
    top: 12%;
  }
  .home-walk__intro-content p {
    top: 50%;
  }
  .home-walk__intro-content > div:last-child {
    bottom: 17%;
  }
}
@media (max-height: 840px) and (min-width: 800px) {
  .home-walk__intro-content p {
    top: 65%;
  }
  .home-walk__intro-content > div:last-child {
    bottom: 17%;
    left: calc(10% - 33px);
    width: 200px;
    left: 200px;
    bottom: 8%;
  }
  .home-walk__intro-content > div:last-child svg {
    margin-top: 0;
    margin-left: 15px;
    position: relative;
    top: 10px;
  }
}
@media (max-width: 800px) {
  .home-walk__intro-content > div:nth-child(1) {
    transform: scale3d(0.65, 0.65, 1);
    top: 4%;
  }
  .home-walk__intro-content p {
    top: 50%;
    font-size: 16px;
  }
}
@media (max-width: 720px) {
  .home-walk__intro-content > div:nth-child(1) {
    transform: scale3d(0.65, 0.65, 1);
    top: 21%;
  }
}
@media (max-width: 340px) {
  .home-walk__intro-content > div:nth-child(1) {
    transform: scale3d(0.5, 0.5, 1);
    top: 22%;
    left: calc(50% - 150px);
  }
  .home-walk__intro-content p {
    top: 50%;
    font-size: 18px;
  }
}

@media (max-width: 720px) {
  .home-walk .home-walk__intro-content {
    display: block;
  }
  .home-walk .home-walk__intro-content > div:nth-child(1) h1 {
    display: block;
  }
  .home-walk .home-walk__intro-content > div:nth-child(1) img:nth-child(2) {
    display: block;
  }
  .home-walk .home-walk__intro-content > div:nth-child(1) img:nth-child(3) {
    display: block;
  }
  .home-walk .home-walk__intro-content > div:nth-child(1) img:nth-child(4) {
    display: block;
  }
  .home-walk .home-walk__intro-content p {
    display: block;
  }
  .home-walk .home-walk__intro-content div:last-child {
    display: block;
  }
}
@media (min-width: 720px) {
  .home-walk[data-intro-stage="0"] .home-walk__intro-content {
    display: none;
  }
  .home-walk[data-intro-stage="0"] .home-walk__intro-init {
    display: none;
  }

  .home-walk[data-intro-stage="1"] .home-walk__intro-init {
    display: none;
  }

  .home-walk[data-intro-stage="2"] .home-walk__intro-content {
    display: none;
  }
  .home-walk[data-intro-stage="2"] .home-walk__intro-content > div:nth-child(1) h1 {
    display: none;
  }
  .home-walk[data-intro-stage="2"] .home-walk__intro-content > div:nth-child(1) img:nth-child(2) {
    display: none;
  }
  .home-walk[data-intro-stage="2"] .home-walk__intro-content > div:nth-child(1) img:nth-child(3) {
    display: none;
  }
  .home-walk[data-intro-stage="2"] .home-walk__intro-content > div:nth-child(1) img:nth-child(4) {
    display: none;
  }
  .home-walk[data-intro-stage="2"] .home-walk__intro-content p {
    display: none;
  }
  .home-walk[data-intro-stage="2"] .home-walk__intro-content div:last-child {
    display: none;
  }

  .home-walk[data-intro-stage="3"] .home-walk__intro-content {
    display: flex;
  }
  .home-walk[data-intro-stage="3"] .home-walk__intro-content > div:nth-child(1) h1 {
    display: block;
  }
  .home-walk[data-intro-stage="3"] .home-walk__intro-content > div:nth-child(1) img:nth-child(2) {
    display: block;
  }
  .home-walk[data-intro-stage="3"] .home-walk__intro-content > div:nth-child(1) img:nth-child(3) {
    display: block;
  }
  .home-walk[data-intro-stage="3"] .home-walk__intro-content > div:nth-child(1) img:nth-child(4) {
    display: block;
  }
  .home-walk[data-intro-stage="3"] .home-walk__intro-content p {
    display: none;
  }
  .home-walk[data-intro-stage="3"] .home-walk__intro-content div:last-child {
    display: none;
  }

  .home-walk[data-intro-stage="4"] .home-walk__intro-content {
    display: flex;
  }
  .home-walk[data-intro-stage="4"] .home-walk__intro-content > div:nth-child(1) h1 {
    display: block;
  }
  .home-walk[data-intro-stage="4"] .home-walk__intro-content > div:nth-child(1) img:nth-child(2) {
    display: block;
  }
  .home-walk[data-intro-stage="4"] .home-walk__intro-content > div:nth-child(1) img:nth-child(3) {
    display: block;
  }
  .home-walk[data-intro-stage="4"] .home-walk__intro-content > div:nth-child(1) img:nth-child(4) {
    display: block;
  }
  .home-walk[data-intro-stage="4"] .home-walk__intro-content p {
    display: block;
  }
  .home-walk[data-intro-stage="4"] .home-walk__intro-content div:last-child {
    display: none;
  }

  .home-walk[data-intro-stage="5"] .home-walk__intro-content {
    display: flex;
  }
  .home-walk[data-intro-stage="5"] .home-walk__intro-content > div:nth-child(1) h1 {
    display: block;
  }
  .home-walk[data-intro-stage="5"] .home-walk__intro-content > div:nth-child(1) img:nth-child(2) {
    display: block;
  }
  .home-walk[data-intro-stage="5"] .home-walk__intro-content > div:nth-child(1) img:nth-child(3) {
    display: block;
  }
  .home-walk[data-intro-stage="5"] .home-walk__intro-content > div:nth-child(1) img:nth-child(4) {
    display: block;
  }
  .home-walk[data-intro-stage="5"] .home-walk__intro-content p {
    display: block;
  }
  .home-walk[data-intro-stage="5"] .home-walk__intro-content div:last-child {
    display: block;
  }
}
@media (max-width: 420px) and (min-height: 600px) {
  .home-walk__intro-content p {
    top: 53% !important;
  }
  .home-walk__intro-content > div:last-child {
    bottom: 10% !important;
  }
}
.home-walk__scroll-points {
  width: 100%;
  height: 400vh;
}

.home-walk__scroll-points-tracker {
  position: sticky;
  z-index: 4;
  top: 50%;
  height: 0;
  width: 100vw;
  list-style-type: none;
  margin: 0;
  padding-left: calc(100vw - 80px);
  opacity: 0;
  transform: translate3d(200px, 0, 0);
  transition: all 1200ms cubic-bezier(0.22, 1, 0.36, 1);
}
@media (max-width: 960px) {
  .home-walk__scroll-points-tracker {
    padding-left: calc(100vw - 45px);
  }
}
@media (max-width: 920px) {
  .home-walk__scroll-points-tracker {
    display: none;
  }
}
.home-walk__scroll-points-tracker li {
  width: 20px;
  height: 20px;
  background-color: #c4c4c4;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  border-radius: 100%;
  cursor: pointer;
}
.home-walk[data-current-section="1"] .home-walk__scroll-points-tracker li:nth-child(1), .home-walk[data-current-section="2"] .home-walk__scroll-points-tracker li:nth-child(2), .home-walk[data-current-section="3"] .home-walk__scroll-points-tracker li:nth-child(3), .home-walk[data-current-section="4"] .home-walk__scroll-points-tracker li:nth-child(4), .home-walk[data-current-section="5"] .home-walk__scroll-points-tracker li:nth-child(5) {
  background-color: #59CAF5;
}

.home-walk[data-current-section="1"] .home-walk__scroll-point:nth-child(2) .home-walk__triangles-bottom, .home-walk[data-current-section="1"] .home-walk__scroll-point:nth-child(2) .home-walk__triangles-top {
  visibility: visible;
}
.home-walk[data-current-section="1"] .home-walk__scroll-point:nth-child(2) h2 {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="1"] .home-walk__scroll-point:nth-child(2) p {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="1"] .home-walk__scroll-point:nth-child(2) a {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="1"] .home-walk__scroll-point:nth-child(2) .home-walk__scroll-point-img {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.home-walk[data-current-section="2"] .home-walk__scroll-point:nth-child(3) .home-walk__triangles-bottom, .home-walk[data-current-section="2"] .home-walk__scroll-point:nth-child(3) .home-walk__triangles-top {
  visibility: visible;
}
.home-walk[data-current-section="2"] .home-walk__scroll-point:nth-child(3) h2 {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="2"] .home-walk__scroll-point:nth-child(3) p {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="2"] .home-walk__scroll-point:nth-child(3) a {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="2"] .home-walk__scroll-point:nth-child(3) .home-walk__scroll-point-img {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.home-walk[data-current-section="3"] .home-walk__scroll-point:nth-child(4) .home-walk__triangles-bottom, .home-walk[data-current-section="3"] .home-walk__scroll-point:nth-child(4) .home-walk__triangles-top {
  visibility: visible;
}
.home-walk[data-current-section="3"] .home-walk__scroll-point:nth-child(4) h2 {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="3"] .home-walk__scroll-point:nth-child(4) p {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="3"] .home-walk__scroll-point:nth-child(4) a {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="3"] .home-walk__scroll-point:nth-child(4) .home-walk__scroll-point-img {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.home-walk[data-current-section="4"] .home-walk__scroll-point:nth-child(5) .home-walk__triangles-bottom, .home-walk[data-current-section="4"] .home-walk__scroll-point:nth-child(5) .home-walk__triangles-top {
  visibility: visible;
}
.home-walk[data-current-section="4"] .home-walk__scroll-point:nth-child(5) h2 {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="4"] .home-walk__scroll-point:nth-child(5) p {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="4"] .home-walk__scroll-point:nth-child(5) a {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="4"] .home-walk__scroll-point:nth-child(5) .home-walk__scroll-point-img {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.home-walk[data-current-section="5"] .home-walk__scroll-point:nth-child(6) .home-walk__triangles-bottom, .home-walk[data-current-section="5"] .home-walk__scroll-point:nth-child(6) .home-walk__triangles-top {
  visibility: visible;
}
.home-walk[data-current-section="5"] .home-walk__scroll-point:nth-child(6) h2 {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="5"] .home-walk__scroll-point:nth-child(6) p {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="5"] .home-walk__scroll-point:nth-child(6) a {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.home-walk[data-current-section="5"] .home-walk__scroll-point:nth-child(6) .home-walk__scroll-point-img {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.home-walk__scroll-point {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: relative;
  overflow: hidden;
}
@media (min-width: 660px) {
  .home-walk__scroll-point h2 {
    transform: translate3d(0, 25px, 0);
    opacity: 0;
    transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  }
  .home-walk__scroll-point p {
    transform: translate3d(0, 50px, 0);
    opacity: 0;
    transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  }
  .home-walk__scroll-point a {
    transform: translate3d(0, 70px, 0);
    opacity: 0;
    transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
  }
}
.home-walk__scroll-point h2 {
  transform: translate3d(0, 0, 0);
}
.home-walk__scroll-point p {
  transform: translate3d(0, 0, 0);
}
.home-walk__scroll-point a {
  transform: translate3d(0, 0, 0);
}
.home-walk__scroll-point:nth-child(2) {
  background-color: #fff;
}
.home-walk__scroll-point:nth-child(2) a svg {
  fill: #002B54;
}
.home-walk__scroll-point:nth-child(2) h2 {
  color: #002B54;
}
.home-walk__scroll-point:nth-child(2) p {
  color: #002B54;
}
.home-walk__scroll-point:nth-child(3) {
  background-color: #002B54;
}
.home-walk__scroll-point:nth-child(3) a svg {
  fill: #59CAF5;
}
.home-walk__scroll-point:nth-child(3) h2 {
  color: #59CAF5;
}
.home-walk__scroll-point:nth-child(3) p {
  color: #fff;
}
.home-walk__scroll-point:nth-child(4) {
  background-color: #fff;
}
.home-walk__scroll-point:nth-child(4) a svg {
  fill: #002B54;
}
.home-walk__scroll-point:nth-child(4) h2 {
  color: #002B54;
}
.home-walk__scroll-point:nth-child(4) p {
  color: #002B54;
}
.home-walk__scroll-point:nth-child(5) {
  background-color: #4BABD3;
}
.home-walk__scroll-point:nth-child(5) a svg {
  fill: #002B54;
}
.home-walk__scroll-point:nth-child(5) h2 {
  color: #002B54;
}
.home-walk__scroll-point:nth-child(5) p {
  color: #fff;
}
.home-walk__scroll-point:nth-child(6) {
  background-color: #fff;
}
.home-walk__scroll-point:nth-child(6) a svg {
  fill: #002B54;
}
.home-walk__scroll-point:nth-child(6) h2 {
  color: #002B54;
}
.home-walk__scroll-point:nth-child(6) p {
  color: #002B54;
}

.home-walk__scroll-point-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0, auto, auto, 0);
  overflow: hidden;
  z-index: 2;
}

.home-walk__scroll-point-fixed {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 80px;
}

.home-walk__inner-scroll-point {
  max-width: 1200px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  height: 100%;
  align-items: center;
}
@media (max-width: 960px) {
  .home-walk__inner-scroll-point {
    flex-wrap: wrap;
    height: 80vh;
    padding-top: 10vh;
  }
  .home-walk__inner-scroll-point > * {
    width: 100% !important;
    padding: 0 !important;
  }
  .home-walk__inner-scroll-point .home-walk__scroll-point-content {
    order: 2;
  }
  .home-walk__inner-scroll-point .home-walk__scroll-point-img {
    margin: 0 auto;
    order: 1;
    margin-bottom: 0px;
    width: 50% !important;
    padding-bottom: 50% !important;
  }
}
@media (max-width: 720px) {
  .home-walk__inner-scroll-point .home-walk__scroll-point-img {
    margin: 0 auto;
    order: 1;
    margin-bottom: 0px;
    width: 100% !important;
    padding-bottom: calc(100% - 4px) !important;
    border: 2px solid #fff;
  }
  .home-walk__inner-scroll-point .home-walk__scroll-point-content p {
    font-size: 22px;
  }
}

.home-walk__scroll-point-follow {
  text-align: center;
  width: 100%;
  padding-top: 20vh;
}
.home-walk__scroll-point-follow h2 {
  font-size: 60px;
  color: #00AEEF !important;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 72px;
}
.home-walk__scroll-point-follow h2 + p {
  font-size: 24px;
  padding: 0;
  margin: 0;
  color: #002B54;
  font-weight: 400;
}
.home-walk__scroll-point-follow ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.home-walk__scroll-point-follow ul li {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  color: #002B54;
  cursor: pointer;
}
.home-walk__scroll-point-follow ul li:after {
  content: "|";
  display: inline-block;
  margin: 0 20px;
}
.home-walk__scroll-point-follow ul li:last-child:after {
  display: none;
}
@media (max-width: 960px) {
  .home-walk__scroll-point-follow ul {
    display: block;
    margin-top: 30px;
  }
  .home-walk__scroll-point-follow ul li {
    display: block;
    margin-bottom: 20px;
  }
  .home-walk__scroll-point-follow ul li:after {
    display: none;
  }
}
.home-walk__scroll-point-follow svg {
  margin-top: 40px;
  fill: #002B54;
  transform: rotateZ(90deg);
}
@media (max-width: 720px) {
  .home-walk__scroll-point-follow h2 {
    font-size: 26px;
    line-height: 32px;
  }
}

.home-walk__scroll-point-content {
  width: 50%;
  padding-right: 60px;
}
.home-walk__scroll-point-content h2 {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 10px;
  letter-spacing: 0.05em;
}
.home-walk__scroll-point-content p {
  margin: 0;
  font-size: 40px;
  font-weight: 300;
  padding: 0;
}
.home-walk__scroll-point-content a {
  display: block;
  margin-top: 20px;
}
@media (max-width: 720px) {
  .home-walk__scroll-point-content p {
    font-size: 28px;
  }
}

.home-walk__scroll-point-img {
  width: 50%;
  padding-bottom: 50%;
  background-color: #002B54;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  border: 6px solid #fff;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.home-walk__point-vid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.home-walk__zoom-points-container {
  position: relative;
  width: 100%;
  background-color: #002B54;
}

.home-walk__zoom-points {
  display: block;
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.home-walk__zoom-point {
  position: absolute;
  max-width: 960px;
  width: 100vw;
  transform: translateZ(-2000px);
  opacity: 0;
}

.home-walk__zoom-point-inner {
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
}
@media (max-width: 720px) {
  .home-walk__zoom-point-inner {
    width: 130%;
    padding-bottom: 130%;
    margin-left: -15%;
  }
}

.home-walk__zoom-point-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  color: #002B54;
}
.home-walk__zoom-point-content > div {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 100px;
}
@media (max-width: 720px) {
  .home-walk__zoom-point-content > div {
    padding: 20%;
  }
  .home-walk__zoom-point-content > div a {
    font-size: 12px !important;
    padding: 10px 30px !important;
  }
}
.home-walk__zoom-point-content > div h2, .home-walk__zoom-point-content > div h3, .home-walk__zoom-point-content > div p {
  display: block;
  width: 100%;
}
.home-walk__zoom-point-content > div a {
  display: inline-block;
}
.home-walk__zoom-point-content > div h2 {
  color: #002B54;
  font-size: 126px;
  font-weight: 100;
  margin-bottom: 10px;
  margin-top: 0;
}
.home-walk__zoom-point-content > div h3 {
  color: #002B54;
  font-weight: 400;
}
.home-walk__zoom-point-content > div img {
  display: block;
  width: 100%;
  padding: 0 25%;
  height: auto;
  margin: 30px 0;
}
.home-walk__zoom-point-content > div p {
  color: #000;
}

.home-walk__zoom-point-a {
  opacity: 1;
}
.home-walk__zoom-point-a h3 {
  font-size: 46px;
}
@media (max-width: 720px) {
  .home-walk__zoom-point-a h2 {
    font-size: 58px !important;
  }
  .home-walk__zoom-point-a h3 {
    font-size: 28px;
  }
}

.home-walk__zoom-point-b {
  opacity: 0;
  transform: translateY(100px);
}
.home-walk__zoom-point-b h3 {
  font-size: 40px;
  margin-bottom: 20px;
}
.home-walk__zoom-point-b p {
  display: block;
  font-size: 16px;
  margin: 0;
  padding: 0 3.5vw;
}
.home-walk__zoom-point-b p + p {
  margin-top: 20px;
}
.home-walk__zoom-point-b a {
  margin-top: 10px;
}
@media (max-width: 720px) {
  .home-walk__zoom-point-b h3 {
    font-size: 26px;
  }
  .home-walk__zoom-point-b p {
    padding: 0;
    font-size: 14px;
  }
  .home-walk__zoom-point-b p + p {
    margin-top: 10px;
  }
  .home-walk__zoom-point-b a {
    margin-top: 20px;
  }
  .home-walk__zoom-point-b img {
    display: none !important;
  }
}

.home-walk__conclude {
  min-height: 35vh;
  padding: 100px 0;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #E7EEF2;
  overflow: hidden;
  height: calc(100vh - 131px) !important;
}
.home-walk__conclude svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.home-walk__conclude div {
  position: relative;
  z-index: 2;
  width: 100%;
}
.home-walk__conclude h3 {
  width: 100%;
  font-size: 40px;
  font-weight: 100;
  color: #0980B2;
  margin-bottom: 10px;
}
.home-walk__conclude p {
  font-size: 16px;
}
.home-walk__conclude a {
  margin-top: 40px;
}

.home-walk__next-action {
  cursor: pointer;
}

header {
  z-index: 10;
  position: relative;
  background-color: #01386A;
}
header > div {
  height: 112px !important;
}
header .menu-item a {
  color: #fff !important;
}

footer {
  margin-top: 0 !important;
}
/*# sourceMappingURL=index.758188d1.css.map */
